globalThis["__sentryRewritesTunnelPath__"] = "/_monitoring";
globalThis["SENTRY_RELEASE"] = {"id":"28090bbdc8b72648cdbf3253dc48f628c2a91c14"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
// eslint-disable-next-line path/no-relative-imports
import clientConfig from '../../packages/shared-client/config';

clientConfig.sentry.enabled = true; // TODO: REMOVE

if (clientConfig.sentry.enabled) {
	Sentry.init({
		dsn: 'https://69ef51b1808baf5082d0762f0cb8bcf6@o4507387221573632.ingest.us.sentry.io/4507387225374720',

		// Adjust this value in production, or use tracesSampler for greater control
		tracesSampleRate: 0.2,

		// Setting this option to true will print useful information to the console while you're setting up Sentry.
		debug: false,

		replaysOnErrorSampleRate: 0, // disabled

		// This sets the sample rate to be 10%. You may want this to be 100% while
		// in development and sample at a lower rate in production
		replaysSessionSampleRate: 0, // disabled

		integrations: [
			// // You can remove this option if you're not planning to use the Sentry Session Replay feature:
			// 	Sentry.replayIntegration({
			// 		// Additional Replay configuration goes in here, for example:
			// 		maskAllText: true,
			// 		blockAllMedia: true,
			// 	}),
			Sentry.thirdPartyErrorFilterIntegration({
				// Specify the application keys that you specified in the Sentry bundler plugin
				filterKeys: ['savee-www'],

				// Defines how to handle errors that contain third party stack frames.
				// Possible values are:
				// - 'drop-error-if-contains-third-party-frames'
				// - 'drop-error-if-exclusively-contains-third-party-frames'
				// - 'apply-tag-if-contains-third-party-frames'
				// - 'apply-tag-if-exclusively-contains-third-party-frames'
				behaviour: 'drop-error-if-exclusively-contains-third-party-frames',
			}),
		],
	});
}
